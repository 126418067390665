import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/privacy/',
    name: 'privacy',
    component: () => import('../views/Privacy.vue')
  },
  {
    path: '/search/:q',
    name: 'search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/gmt/',
    name: 'gmt',
    component: () => import('../views/Gmt.vue')
  },
  {
    path: '/gmt/:q',
    name: 'gmtquery',
    component: () => import('../views/Gmt_search.vue')
  },
  {
    path: '/:region/',
    name: 'EventDetail',
    component: () => import('../views/Country.vue')
  },
  {
    path: '/:region/:city',
    name: 'region',
    component: () => import('../views/City.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    window.scrollTo(0, 0)
  }
})

export default router
