<template>
   <header id="header"  class="header-scrolled">
     <div class="header-wrapp _header">
      <header class="header" role="heading">
        <div class="header__inner">
          <div class="header-logo">
            <a class="logo" :href="$router.resolve({name: 'Home'}).href">
           <!-- <router-link class="logo"  :to="{path:'/'}" > -->
              <img src="@/assets/timezone-icon-13.png" width="30px">
              Clock.Zone
            <!-- </router-link> -->
            </a>
          </div>
           <div v-if="!headerShow"  class="mobile-menu" @click="headerShow = true">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div v-if="headerShow" class="mobile-menu closeNav" @click="headerShow = false">
              <div></div>
               <div></div>
            </div>
          <div class="search">
          
            <autocomplete
              @selected="setCity"
               placeholder="Search for Cities"
              v-model="city"
              :source= "$store.state.api_url + '_/_search?q='"
              results-property="city"
              results-display="city">
            </autocomplete>
          </div>
          <div class="header-nav" v-bind:class="{'show-header-nav':headerShow}">
            <ul class="navigation-list">
              <li @click="headerShow = false" class="navigation-list__sns">
                <a class="itm" :href="$router.resolve({name: 'Home'}).href">Home</a>
                <!-- <router-link class="itm" :to="{path:'/'}" >
                  Home
                </router-link> -->
                </li>
              <!-- <router-link class="itm" :to="{path:'/about'}" >
                  About
                </router-link>  -->
               <li @click="reloadpage" class="navigation-list__sns"> 
                 <router-link class="itm" :to="{path:'/about'}" >
                  About
                </router-link> 
                <!-- <a class="itm" :href="$router.resolve({name: 'about'}).href">About</a> -->
               </li>
              <li @click="headerShow = false" class="navigation-list__sns">
                <a class="itm" :href="$router.resolve({path: '/gmt/'}).href">GMT</a>
                <!-- <router-link class="itm" :to="{path:'/gmt/'}" >
                  GMT
                </router-link> -->
                </li>
            
            </ul>
            <div class="search src2">
          
            <autocomplete
              @selected="setCity"
               placeholder="Search for Cities"
              v-model="city"
              :source= "$store.state.api_url + '_/_search?q='"
              results-property="city"
              results-display="city">
            </autocomplete>
          </div>
          </div>
        </div>

      </header>
    </div>
    

  </header>
</template>

<script>
import axios from "axios"
import Autocomplete from 'vuejs-auto-complete'
export default {

    data(){
        return{
           navigation:[],
           menuOpen:false,
           api_url:"https://howmanydaystill.com/_read",
           city:"",
           headerShow:false
        }
    },
    components:{
        Autocomplete
    },
    watch: {
    "$route.params": {
        handler (newValue, oldValue) {
           
        },
        immediate: true
        }
    },
    created(){
        this.init()
    },
    methods:{
      setCity(){
        setTimeout(f=>{
          this.headerShow = false
           this.$router.push({path:"/"+this.city});
        },200)
       
      },
      reloadpage() {
        window.location.href = '/about'
        headerShow = false
      },
        toggleMenu(){
            if(this.menuOpen){
                this.menuOpen = false
            }else{
                this.menuOpen = true
            }
        },
        init(){
            axios.post(this.api_url, {table:"navigation"}).then(response=>{
                  //console.log(response)
                  for (var ind in response.data) {
                      var d = response.data[ind]
                      this.navigation.push(d)
                  }
                  this.loading = false
                  //console.log(this.events)
              })
        }
    }
}
</script>

